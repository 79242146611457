.container-restaurant {
  margin: 10px;
  margin-top: 20px;
}
.container-restaurant > div > label {
  display: flex;
  border-radius: 100;
  justify-content: center;
  align-items: center !important;
}

.button-welcome {
  font-size: 20px !important;
  height: 50px !important;
  margin-top: 30px;
}

.radio-welcome {
  font-size: 17px !important;
  height: 40px !important;
}

.title-welcome {
  margin-bottom: 20px !important;
  padding: 10px;
  text-align: center !important;
}

.subtitle-welcome {
  font-size: 22px !important;
  margin-bottom: 10px !important;
  padding: 10px;
  text-align: center !important;
}

.subsubtitle-welcome {
  font-size: 20px !important;
  margin-bottom: 10px !important;
  padding: 10px;
  text-align: center !important;
}

.container-welcome {
  display: flex;
  align-items: center !important;
}

/* Estilos para pantallas pequeñas (sm) */
@media (max-width: 379px) {
  .button-welcome {
    width: 240px !important;
  }
  .radio-welcome {
    width: 120px !important;
  }
  .title-welcome {
    font-size: 40px !important;
  }
  .container-welcome {
    align-items: center !important;
  }
  .subtitle-welcome,
  .subsubtitle-welcome {
    text-align: center !important;
  }
}

/* Estilos para pantallas medianas (md) */
@media (min-width: 380px) and (max-width: 499px) {
  .button-welcome {
    width: 200px !important;
    margin-left: 10px !important;
  }
  .radio-welcome {
    width: 130px !important;
    font-size: 19px !important;
    height: 45px !important;
  }
  .title-welcome {
    font-size: 45px !important;
    margin-bottom: 30px !important;
    text-align: left !important;

  }
  .container-welcome {
    align-items: flex-start !important;
  }
  .subtitle-welcome,
  .subsubtitle-welcome {
    text-align: left !important;
  }
}

/* Estilos para pantallas grandes (lx) */
@media (min-width: 500px) and (max-width: 765px) {
  .button-welcome {
    font-size: 25px !important;
    width: 200px !important;
    margin-left: 10px !important;
  }
  .radio-welcome {
    width: 140px !important;
    font-size: 19px !important;
    height: 45px !important;
  }
  .title-welcome {
    font-size: 50px !important;
    margin-bottom: 0 !important;
    text-align: left !important;
  }
  .container-welcome {
    align-items: flex-start !important;
  }
  .subtitle-welcome {
    text-align: left !important;
  }
  .subsubtitle-welcome {
    text-align: left !important;
  }
}

/* Estilos para pantallas extra grandes (ex) */
@media (min-width: 766px) {
  .button-welcome {
    font-size: 30px !important;
    height: 70px !important;
    width: 250px !important;
    margin-left: 50px !important;
  }
  .radio-welcome {
    width: 150px !important;
    font-size: 20px !important;
    height: 45px !important;
  }
  .title-welcome {
    font-size: 65px !important;
    margin-bottom: 0 !important;
    margin-left: 50px !important;
    max-width: 800px;
    text-align: left !important;

  }
  .subtitle-welcome {
    text-align: left !important;
    margin-left: 50px !important;
  }
  .subsubtitle-welcome {
    text-align: left !important;
    margin-left: 50px !important;
  }
  .container-welcome {
    align-items: flex-start !important;
    margin-bottom: 30px !important;
    margin-left: 60px !important;
  }
  .radio-group-welcome {
    margin-left: 40px !important;
  }
}
