.container-card {
  background-color: white;
  width: 320px;
  height: 110px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  margin-left: 0px;
  transition: all 0.1s linear;
  position: relative;
  overflow: hidden;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(4px) brightness(60%);
  z-index: 1;
}

.content-card {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  height: 100%;
  padding: 5px;
}

.svg-color-white {
  filter: invert(80%);
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
}
