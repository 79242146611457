.navbar-custom {
  position: fixed;
  background-color: transparent;
  box-shadow: none;
  font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
  top: -1px;
  transition: 0.2s ease-out;
  padding: 2rem 1rem;
  width: 100%;
  margin: 0px;
  z-index: 2000;
  transition: all 0.3s linear;
  height: 90px;
}

.scrolled {
  padding: 1rem;
  box-shadow: 0 0.325rem 0.375rem 0 rgb(0 0 0 / 10%);
  background-color: #221e1f !important;
  height: 70px;
}

.container-header {
  padding: 0px;
}

.scrolled > a {
  padding: 1rem;
  box-shadow: 0 0.325rem 0.375rem 0 rgb(0 0 0 / 10%);
  background-color: white !important;
}

.items-not-scrolled {
  color: white !important;
}

.items-scrolled {
  color: white !important;
}

.nav-not-scrolled {
  padding: 10px 20px 10px 20px;
  transition: all 0.3s linear;
}

.nav-scrolled {
  padding: 10px 20px 10px 20px;
  transition: all 0.3s linear;
}

.items-not-scrolled > a {
  color: white !important;
}

.items-scrolled > a {
  color: #221e1f !important;
}

.size_animate {
  transition: all 0.1s linear;
  font-size: 20px !important; 
}

.size_animate:hover {
  transform: scale(1.05);
}

.select-restaurant > div {
  color: white !important;
  background-color: transparent !important;
}

.select-restaurant > span {
  color: white !important;
}

.select-restaurant > div > span {
  font-size: 20px !important;
  font-weight: 600 !important;
}
