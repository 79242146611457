.users-calendar-top-container {
  min-height: 90vh;
  width: 100%;
  background-color: #221e1f;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.users-calendar-container {
  padding: 20px;
  width: 100%;
}

.title-container {
  width: 85%;

}

/* @media (max-width: 500px) {
  .images-container {
    width: 100% !important;
  }
}

@media (min-width: 500px) and (max-width: 910px) {
  .images-container {
    width: 90% !important;
  }
}

@media (min-width: 910px) and (max-width: 1368px) {
  .images-container {
    width: 80% !important;
  }
} */
