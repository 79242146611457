.heading {
    padding: 1rem 0;
    font-size: 3.5rem;
    text-align: center;
}

.swiper_container {
    height: 100vh;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide-custom {
    max-width: 80%;
    max-height: 100%;
}

.swiper-slide {
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.swiper-slide>div>div>img {
    object-fit: cover;
    border-radius: 2rem;
    max-height: 75vh;
    width: 100%;
}

@media (max-width:500px) {
    .swiper-slide {
        width: 90%;

    }

    .swiper-slide-custom {
        max-width: 100%;
    }
}

@media (min-width:500px) and (max-width:990px) {
    .swiper-slide {
        width: 90%;

    }

    .swiper-slide-custom {
        max-width: 90%;
    }
}


@media (min-width:990px) and (max-width: 1200px) {
    .swiper-slide-custom {
        max-width: 85%;
    }
}



.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}