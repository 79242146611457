.images-container {
  width: 60% !important;
  height: 100% !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width:500px) {
  .images-container {
    width: 100% !important;
  }
}

@media (min-width:500px) and (max-width:910px) {
  .images-container {
    width: 90% !important;
  }
}

@media (min-width:910px) and (max-width:1368px) {
  .images-container {
    width: 80% !important;
  }
}