.users-text-container {
  padding: 40px;
  background-color: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.users-text-content {
  padding-right: 20px; /* Espaciado a la derecha para separar del lado de la imagen */
}

.users-text-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 15px;
}

.users-text-img {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.users-text-img:hover {
  transform: scale(1.05);
}

.menu-list {
  padding-left: 20px;
}

.menu-list-item {
  margin-bottom: 10px;
}

.menu-link {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.section-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.section-card:hover {
  transform: scale(1.02);
}

.container-sections {
  flex-wrap: nowrap;
}

@media (max-width: 500px) {
  .section-container {
    margin-bottom: 10px !important;
  }
  .container-sections {
    flex-wrap: wrap;
    padding: 2px;
  }
}

@media (min-width: 500px) and (max-width: 990px) {
  .section-container {
    margin: 5px !important;
  }
  .container-sections {
    flex-wrap: wrap;
    padding: 5px;
  }
}

@media (min-width: 990px) {
  .section-container {
    margin: 5px !important;
  }
  .container-sections {
    padding: 10px;
  }
}
