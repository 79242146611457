.visit-us {
  background-color: gainsboro;
  color: white;
  padding: 40px;
  overflow: hidden;
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 7px gray;
}

.contact-info {
  position: relative;
}

.info-block {
  padding: 20px;
  flex-grow: 1;
  flex-basis: 50%;
}

.info-block .icon {
  font-size: 80px;
  color: #f47b22;
}

.social-media a {
  font-size: 24px;
  margin-right: 10px;
  color: white;
}

.social-media a:hover {
  color: #f47b22;
}

.visit-hoverable {
  text-decoration: none;
}

.visit-hoverable:hover {
  color: white !important;
  cursor: pointer;
}

.visit-large-icons {
  font-size: 80px !important;
  color: #f47b22 !important;
}

.visit-large-icons:hover {
  transform: scale(1.1); /* Efecto de escala para el hover */
}

.visit-icons {
  font-size: 50px !important;
  color: #f47b22 !important;
}

.visit-icons:hover {
  transform: scale(1.1); /* Efecto de escala para el hover */
}

.map iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 20px;
}

@media (max-width: 500px) {
  .overlay {
    padding: 10px;
  }

  .info-block {
    padding: 5px;
  }

  .info-block .icon {
    font-size: 50px;
  }
}

@media (min-width: 500px) and (max-width: 990px) {
  .overlay {
    padding: 20px;
  }
  .info-block {
    padding: 10px;
  }
  .info-block .icon {
    font-size: 60px;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  .overlay {
    padding: 25px;
  }
  .info-block {
    padding: 15px;
  }
}
